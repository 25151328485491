<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.38 1.43622C12.0159 1.0589 11.4115 1.05901 11.0475 1.43646L4.47651 8.25141C4.21058 8.52721 4.21058 8.964 4.47651 9.2398L11.0475 16.0548C11.4115 16.4322 12.0159 16.4323 12.38 16.055C12.726 15.6964 12.7261 15.1284 12.3802 14.7697L6.57091 8.7456L12.3802 2.72151C12.7261 2.36286 12.726 1.79478 12.38 1.43622Z"
      fill="#00A3FF"
    />
  </svg>
</template>
<script>
  export default {
    name: 'LeftArrowSvg'
  }
</script>
<style lang="scss" scoped></style>
