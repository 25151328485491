<template>
  <div
    v-if="verification"
    class="reset-block"
  >
    <div class="reset">
      <logotype style="margin-top: 0; margin-bottom: 51px" />
      <div class="reset__form">
        <p class="text">Восстановление доступа</p>
        <div
          v-if="step === 0 && !$route.params.token"
          class="email_text"
        >
          Введите E-mail, который вы использовали при регистрации
        </div>
        <div
          v-if="step === 1 && !$route.params.token && !sendEmailError"
          class="success_block"
        >
          Совсем скоро на E-mail {{ email }} будет отправлено письмо со ссылкой для изменения пароля
        </div>
        <div
          v-if="step === 1 && !$route.params.token && sendEmailError"
          class="error_block"
        >
          Произошла ошибка при попытке отправить письмо на почту, попробуйте позже
        </div>
        <e-input
          v-if="!$route.params.token"
          v-model="email"
          size="l"
          style="margin-top: 48px"
          :search-icon="false"
          placeholder=""
          :error="isInputError"
          :error_text="'Введите корректный E-mail'"
          :label="'E-mail'"
        />
        <div v-if="$route.params.token">
          <e-input
            id="pass1"
            v-model="pass"
            style="margin-top: 32px"
            :search-icon="false"
            placeholder=""
            :error="isPassError"
            type="password"
            :error_text="'Введите пароль'"
            :label="'Новый пароль'"
          />
          <e-input
            id="pass2"
            v-model="confirmPass"
            style="margin-top: 48px"
            :search-icon="false"
            placeholder=""
            :error="isConfirmError"
            type="password"
            :error_text="'Введите подтверждение пароля'"
            :label="'Повторите пароль'"
          />
        </div>

        <e-button
          v-if="!$route.params.token"
          style="margin-top: 28px; width: 100%"
          :variant="buttonColor"
          :disabled="disabled"
          :size="'l'"
          @click="clickNext"
        >
          <p
            ref="button_text"
            class="button_p"
            style="margin: 0; color: #fff"
          >
            {{ buttonText }}
          </p>
        </e-button>

        <e-button
          v-if="$route.params.token"
          style="margin-top: 28px; width: 100%"
          variant="primary"
          :size="'l'"
          @click="clickNext"
        >
          <p
            style="margin: 0"
            class="button_p"
          >
            Изменить пароль
          </p>
        </e-button>
        <div class="back">
          <div
            class="button"
            @click.stop="back"
          >
            <left-arrow-svg />
            Вернуться назад
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Logotype from './components/Logotype.vue'
  import LeftArrowSvg from './components/leftArrowSvg.vue'

  export default {
    name: 'ResetPassword',
    components: { Logotype, LeftArrowSvg },
    data() {
      return {
        step: 0,
        isInputError: false,
        email: '',
        disabled: false,
        buttonText: 'Восстановить пароль',
        date: new Date(),
        interval: null,
        pass: '',
        confirmPass: '',
        isPassError: false,
        isConfirmError: false,
        sendEmailError: false,
        verification: true
      }
    },
    computed: {
      buttonColor() {
        return this.disabled ? '' : 'primary'
      }
    },

    watch: {
      disabled() {
        if (this.disabled) {
          this.$refs.button_text.classList.add('button_p__step2')
        } else {
          this.$refs.button_text.classList.remove('button_p__step2')
        }
      },
      email() {
        if (this.isInputError) this.isInputError = false
      },
      pass() {
        if (this.isPassError) this.isPassError = false
      },
      confirmPass() {
        if (this.confirmPass) this.isConfirmError = false
      }
    },

    mounted() {
      if (localStorage.getItem('interval_reset_password')) {
        this.disabled = true
        this.date = localStorage.getItem('interval_reset_password')
        this.timer()
      }
    },

    async created() {
      if (this.$route.params.token) {
        this.verification = false
        this.step = 2
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('./gql/IsTokenValid.graphql'),
            variables: {
              input: {
                apiToken: this.$route.params.token
              }
            }
          })
          if (!data?.IsTokenValid?.status) {
            return this.$router.push('/auth/login')
          }
          this.verification = true
        } catch (e) {
          return this.$router.push('/auth/login')
        }
      }
    },
    methods: {
      back() {
        if (this.step === 0) {
          return this.$router.back()
        }
        this.disabled = false
        this.buttonText = 'Восстановить пароль'
        this.step = 0
      },
      async SendRestorePasswordMail() {
        try {
          await this.$apollo.mutate({
            mutation: require('./gql/SendRestorePasswordMail.graphql'),
            variables: {
              input: {
                email: (this.email + '').toLowerCase()
              }
            }
          })
          this.send()
          this.sendEmailError = false
        } catch (e) {
          this.sendEmailError = true
        }
      },
      async clickNext() {
        if (this.step === 0 && !this.$route.params.token) {
          if (this.validateEmail(this.email)) {
            await this.SendRestorePasswordMail()
            this.step = this.step + 1
          } else {
            this.$noty.error('Введите корректный E-mail')
            this.isInputError = true
          }
          return
        }
        if (this.step === 1 && !this.$route.params.token) {
          if (this.validateEmail(this.email)) {
            await this.SendRestorePasswordMail()
          } else {
            this.$noty.error('Введите корректный E-mail')
            this.isInputError = true
          }
        }
        if (this.$route.params.token) {
          if (!this.pass && !this.confirmPass) {
            this.isConfirmError = true
            this.isPassError = true
            return this.$noty.error('Заполните все поля')
          }
          if (!this.pass) {
            this.isPassError = true
            return this.$noty.error('Введите пароль')
          }
          if (!this.confirmPass) {
            this.isConfirmError = true
            return this.$noty.error('Введите подтверждение пароля')
          }
          if (this.pass === this.confirmPass) {
            try {
              const { data } = await this.$apollo.mutate({
                mutation: require('./gql/RestorePassword.graphql'),
                variables: {
                  input: {
                    password: this.confirmPass,
                    apiToken: this.$route.params.token
                  }
                }
              })
              if (data?.RestorePassword?.status) {
                this.$noty.show('Пароль успешно изменен')
                setTimeout(() => {
                  this.$router.push('/auth/login')
                }, 500)
              } else {
                throw new Error('Ошибка на сервере')
              }
            } catch (e) {
              this.$noty.error('Ошибка на сервере, попробуйте позже')
            }
          } else {
            this.$noty.error('Пароли не совпадают')
          }
        }
      },
      send() {
        this.date = new Date().setMinutes(new Date().getMinutes() + 1)
        localStorage.setItem('interval_reset_password', this.date.toString())
        this.timer()
      },
      validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      },
      password(pass) {
        return String(pass)
          .toLowerCase()
          .match(/[a-zA-Z0-9-_=+()*&^%$#@!]{8,32}/)
      },
      timer() {
        this.disabled = true
        this.interval = setInterval(() => {
          if (this.date > new Date()) {
            if (!this.disabled) {
              clearInterval(this.interval)
              this.interval = null
              this.disabled = false
              localStorage.removeItem('interval_reset_password')
              this.buttonText = 'Восстановить пароль'
              return
            }
            this.buttonText = `Не пришло? Отправить повторно через ${Math.round((this.date - new Date()) / 1000)} сек.`
          } else {
            this.disabled = false
            this.buttonText = 'Не пришло? Отправить повторно'
            clearInterval(this.interval)
            localStorage.removeItem('interval_reset_password')
            this.interval = null
          }
        }, 100)
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .input {
    height: 42px !important;
    font-size: 16px !important;
  }
  .button_p {
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    &__step2 {
      color: #888888 !important;
    }
  }
  .email_text {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #888888;
  }
  .success_block {
    background: #eefffa;
    padding: 22px;
    color: #00cb91;
    border: 1px solid #a5ecd7;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .error_block {
    background: #fffafa;
    border: 1px solid #e53835;
    border-radius: 2px;
    padding: 22px;
    color: #e53835;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  ::v-deep() {
    .repeat {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #888888;
      margin-top: 6px;
    }
  }

  .back {
    margin-top: 100px;

    .button {
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #00a3ff;
    }
  }

  ::v-deep() {
    .button {
      svg {
        margin-right: 13.5px;
      }
    }
  }

  .text {
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #313131;
  }

  ::v-deep .input-block {
    .input {
      height: 42px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #313131;
    }
  }

  .reset {
    width: 446px;
  }
  .reset-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
</style>
