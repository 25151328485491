<template>
  <div class="logo-block">
    <img src="/img/icons/logoAuth.svg" />
  </div>
</template>
<script>
  export default {
    name: 'Logotype'
  }
</script>
<style lang="scss" scoped>
  .logo-block {
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 61px;
    p {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20.0389px;
      line-height: 97.5%;
      color: #000000;
    }
  }
</style>
