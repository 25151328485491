var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.verification ? _c('div', {
    staticClass: "reset-block"
  }, [_c('div', {
    staticClass: "reset"
  }, [_c('logotype', {
    staticStyle: {
      "margin-top": "0",
      "margin-bottom": "51px"
    }
  }), _c('div', {
    staticClass: "reset__form"
  }, [_c('p', {
    staticClass: "text"
  }, [_vm._v("Восстановление доступа")]), _vm.step === 0 && !_vm.$route.params.token ? _c('div', {
    staticClass: "email_text"
  }, [_vm._v(" Введите E-mail, который вы использовали при регистрации ")]) : _vm._e(), _vm.step === 1 && !_vm.$route.params.token && !_vm.sendEmailError ? _c('div', {
    staticClass: "success_block"
  }, [_vm._v(" Совсем скоро на E-mail " + _vm._s(_vm.email) + " будет отправлено письмо со ссылкой для изменения пароля ")]) : _vm._e(), _vm.step === 1 && !_vm.$route.params.token && _vm.sendEmailError ? _c('div', {
    staticClass: "error_block"
  }, [_vm._v(" Произошла ошибка при попытке отправить письмо на почту, попробуйте позже ")]) : _vm._e(), !_vm.$route.params.token ? _c('e-input', {
    staticStyle: {
      "margin-top": "48px"
    },
    attrs: {
      "size": "l",
      "search-icon": false,
      "placeholder": "",
      "error": _vm.isInputError,
      "error_text": 'Введите корректный E-mail',
      "label": 'E-mail'
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }) : _vm._e(), _vm.$route.params.token ? _c('div', [_c('e-input', {
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      "id": "pass1",
      "search-icon": false,
      "placeholder": "",
      "error": _vm.isPassError,
      "type": "password",
      "error_text": 'Введите пароль',
      "label": 'Новый пароль'
    },
    model: {
      value: _vm.pass,
      callback: function ($$v) {
        _vm.pass = $$v;
      },
      expression: "pass"
    }
  }), _c('e-input', {
    staticStyle: {
      "margin-top": "48px"
    },
    attrs: {
      "id": "pass2",
      "search-icon": false,
      "placeholder": "",
      "error": _vm.isConfirmError,
      "type": "password",
      "error_text": 'Введите подтверждение пароля',
      "label": 'Повторите пароль'
    },
    model: {
      value: _vm.confirmPass,
      callback: function ($$v) {
        _vm.confirmPass = $$v;
      },
      expression: "confirmPass"
    }
  })], 1) : _vm._e(), !_vm.$route.params.token ? _c('e-button', {
    staticStyle: {
      "margin-top": "28px",
      "width": "100%"
    },
    attrs: {
      "variant": _vm.buttonColor,
      "disabled": _vm.disabled,
      "size": 'l'
    },
    on: {
      "click": _vm.clickNext
    }
  }, [_c('p', {
    ref: "button_text",
    staticClass: "button_p",
    staticStyle: {
      "margin": "0",
      "color": "#fff"
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])]) : _vm._e(), _vm.$route.params.token ? _c('e-button', {
    staticStyle: {
      "margin-top": "28px",
      "width": "100%"
    },
    attrs: {
      "variant": "primary",
      "size": 'l'
    },
    on: {
      "click": _vm.clickNext
    }
  }, [_c('p', {
    staticClass: "button_p",
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(" Изменить пароль ")])]) : _vm._e(), _c('div', {
    staticClass: "back"
  }, [_c('div', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.back.apply(null, arguments);
      }
    }
  }, [_c('left-arrow-svg'), _vm._v(" Вернуться назад ")], 1)])], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }